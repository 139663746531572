import React from "react";
import { Route, Routes } from "react-router-dom";
import SeeCatalog from "./SeeCatalog";
import SeeCatalogDetails from "./SeeCatalogDetails";

const ShowCaseRoute = () => {
    return (
        <div>
            <Routes>
                <Route path="/catalog-screen/see-catalog" element={<SeeCatalog />} />
                <Route path="/catalog-screen/see-catalog/:id/see-catalog-details" element={<SeeCatalogDetails />} />
            </Routes>
        </div>
    );
};

export default ShowCaseRoute;
