import React, { useEffect, useState } from "react";
import { Row, Col, InputGroup, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { getToken } from "../utils/authUtils";
import axios from "axios";

// Props interface
interface CatalogHeaderProps {
  searchQuery?: string;
  setSearchQuery?: (query: string) => void;
  showSearch?: boolean;
}

// CatalogHeader Component
const CatalogHeader: React.FC<CatalogHeaderProps> = ({
  searchQuery = "",
  setSearchQuery = () => { },
  showSearch = true,
}) => {
  const [organization, setOrganization] = useState<any>(null);
  // eslint-disable-next-line
  const [loadingOrganization, setLoadingOrganization] = useState<boolean>(true);
  const navigate = useNavigate();
  const handleback = () => {
    navigate(-1);
  };
  const getLogo = async () => {

    const userToken = getToken();
    setLoadingOrganization(true);

    const apiUrl = `${process.env.REACT_APP_API_URL}api/public/organizations`;
    console.log("API URL:", apiUrl);

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setOrganization(response.data.data);
    } catch (error) {
      console.error("Error fetching organization logo:", error);
    } finally {
      setLoadingOrganization(false);
    }
  };


  useEffect(() => {
    getLogo();
  }, []);
  return (
    <div className="see-catalog-header position-relative w-100">
      <Row className="d-flex justify-content-between align-items-center w-100 m-0">
        <Col xs={12} md={7} className="d-flex justify-content-between align-items-center my-2 my-md-0">
          <button
            className="btn btn-sm btn-gold uniform-back-btn ms-4"
            onClick={handleback}
          >
            <FontAwesomeIcon icon={faArrowLeft} style={{ color: "white" }} />
          </button>

          {organization && organization.logo ? (
            <img
              src={organization.logo}
              alt="Logo"
              className="img-fluid translate-middle-x header-logo-catalog"
            />
          ) : (
            <div className="skeleton-catalog header-logo-catalog translate-middle-x"></div>
          )}
        </Col>

        <Col xs={12} md={5} className="d-flex justify-content-end my-2 my-md-0">
          {showSearch ? (
            <div className="d-flex align-items-center justify-content-end me-4 custom-width">
              <InputGroup className="w-100">
                <InputGroup.Text className="bg-dark border-0 m-0">
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{ color: "white" }}
                    size="lg"
                  />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search Tile....."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="shadow-sm bg-dark border-0 custom-search"
                  style={{ color: "white" }}
                />
              </InputGroup>
            </div>
          ) : (
            <div className="me-4"></div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CatalogHeader;
