import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { getDecodedToken, getToken } from "../../utils/authUtils";
import { useLocation } from "react-router-dom";
import Select from "react-select";

interface Filter {
  scopes?: { name: string }[];
  filters?: any;
  search?: { value: string };
}

interface BottomsheetProps {
  showBottomsSheetrModal: boolean;
  toggleBottomsheetModal: () => void;
  onImageChange: (imageSrc: object) => void;
  setSelectedImageDetails: any;
  resetRotationAngles: () => void;
}
const SearchBottomsheet: React.FC<BottomsheetProps> = ({
  showBottomsSheetrModal,
  toggleBottomsheetModal,
  onImageChange,
  setSelectedImageDetails,
  resetRotationAngles,
}: BottomsheetProps) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(true);
  const [userToken, setUserToken] = useState<string | null>(null);
  const [AiImagesproductData, setAiImagesproductData] = useState<
    { id: number; image_url_small: string }[]
  >([]);
  const isLikedParam = queryParams.get("is_liked");
  const [favoriteActive, setFavoriteActive] = useState(isLikedParam === "true");
  const [isForWall, setIsForWall] = useState(true);
  const [isForFloor, setIsForFloor] = useState(true);
  // eslint-disable-next-line
  const [visitorId, setVisitorId] = useState<number | null>(null);
  const [tileSizes, setTileSizes] = useState<string[]>([]);
  const [tileColors, setTileColors] = useState<string[]>([]);
  // eslint-disable-next-line
  const [organizationId, setOrganizationId] = useState<number | null>(null);
  const images = [];
  // eslint-disable-next-line
  const [favoriteStatus, setFavoriteStatus] = useState(
    Array(images.length).fill(false)
  );
  // eslint-disable-next-line
  const [showTilesColorModal, setShowTilesColorModal] = useState(false);
  // eslint-disable-next-line
  const [showTilesSizeModal, setShowTilesSizeModal] = useState(false);
  const [tilesizeOptions, setTilesizeOptions] = useState([]);
  const [tilecolorOptions, setTilecolorOptions] = useState([]);
  const [selectedTileSizes, setSelectedTileSizes] = useState<any[]>([]);
  const [selectedTileColor, setSelectedTileColor] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  // eslint-disable-next-line
  const [slidesPerView, setSlidesPerView] = useState(14);
  const updateSelectedTileSizes = (searchQuery: string) => {
    const filteredOptions = tilesizeOptions.filter((option: any) =>
      option.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSelectedTileSizes(filteredOptions);
  };

  const updateSelectedTileColors = (searchQuery: string) => {
    const filteredOptions = tilecolorOptions.filter((option: any) =>
      option.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSelectedTileColor(filteredOptions);
  };
  const useQueryParam = (paramName: any, paramValue: any) => {
    useEffect(() => {
      const currentQueryString = window.location.search;
      const queryParams = new URLSearchParams(currentQueryString);
      queryParams.delete(paramName);
      queryParams.append(paramName, String(paramValue));
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
    }, [paramName, paramValue]);
  };

  const useProductFetcher = (
    favoriteActive: any,
    isForWall: any,
    isForFloor: any
  ) => {
    useEffect(() => {
      getProducts();
    }, [favoriteActive, isForWall, isForFloor]);
  };

  useQueryParam("is_liked", favoriteActive);
  useQueryParam("is_for_wall", isForWall);
  useQueryParam("is_for_floor", isForFloor);
  useProductFetcher(favoriteActive, isForWall, isForFloor);

  useEffect(() => {
    const token = getToken();
    if (token) {
      setUserToken(token);
      const decodedToken = getDecodedToken();
      const OrganizationId = decodedToken.data.organization_id;
      const visitorId = decodedToken.data.id;
      setVisitorId(visitorId);
      setOrganizationId(OrganizationId);
    }
  }, [isOpen]);

  useEffect(() => {
    function updateSlidesPerView() {
      const screenWidth = window.innerWidth;
      const newSlidesPerView = screenWidth < 768 ? 1 : 10;
      setSlidesPerView(newSlidesPerView);
    }
    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);
    return () => {
      window.removeEventListener("resize", updateSlidesPerView);
    };
  }, []);

  useEffect(() => {
    const currentQueryString = window.location.search;
    const queryParams = new URLSearchParams(currentQueryString);
    const colors = queryParams.getAll("color[]");
    const sizes = queryParams.getAll("size[]");
    if (tileColors !== colors || tileSizes !== sizes) {
      getProducts();
    }
    // eslint-disable-next-line
  }, [showTilesSizeModal, showTilesColorModal]);

  useEffect(() => {
    const isLikedParam = queryParams.get("is_liked");
    if (isLikedParam !== null) {
      setFavoriteActive(isLikedParam === "true");
    }
    if (userToken !== null) {
      getProducts();
    }
    // eslint-disable-next-line
  }, [userToken, favoriteStatus]);

  const getProducts = async () => {
    try {
      if (userToken) {
        const currentQueryString = window.location.search;
        const queryParams = new URLSearchParams(currentQueryString);
        const selectedTileColor = queryParams.getAll("color[]");
        const selectedTileSizes = queryParams.getAll("size[]");
        setTileColors(selectedTileColor);
        setTileSizes(selectedTileSizes);

        let filter: Filter = {};

        // filter.scopes = [{ name: "productLike" }];

        filter.filters = [
          { field: "is_for_wall", value: isForWall },
          { field: "is_for_floor", value: isForFloor },
        ];

        if (selectedTileSizes.length > 0) {
          filter.filters.push({
            field: "tiles_size_id",
            operator: "in",
            value: selectedTileSizes,
          });
        }

        if (selectedTileColor.length > 0) {
          filter.filters.push({
            field: "product_color_id",
            operator: "in",
            value: selectedTileColor,
          });
        }

        filter.search = {
          value: searchQuery,
        };

        const apiUrl =
          (process.env.REACT_APP_API_URL as string) +
          "api/public_products/search?is_liked=";

        const response = await axios.post(apiUrl + favoriteActive, filter, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setAiImagesproductData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data from the API:", error);
    }
  };

  const handleImageClick = (imageSrc: any, slideIndex: number) => {
    if (
      imageSrc &&
      Array.isArray(AiImagesproductData) &&
      slideIndex >= 0 &&
      slideIndex < AiImagesproductData.length
    ) {
      onImageChange(imageSrc);

      const selectedImage = AiImagesproductData[slideIndex];
      if (selectedImage && selectedImage.id) {
        const selectedImageDetails: {
          wall_tile_id?: number;
          floor_tile_id?: number;
          searchQuery?: string;
        } = {};

        if (isForWall) {
          selectedImageDetails.wall_tile_id = selectedImage.id;
        }

        if (isForFloor) {
          selectedImageDetails.floor_tile_id = selectedImage.id;
        }

        if (!isForWall && !isForFloor) {
          selectedImageDetails.wall_tile_id = selectedImage.id;
          selectedImageDetails.floor_tile_id = selectedImage.id;
        }
        selectedImageDetails.searchQuery = searchQuery;

        setSelectedImageDetails(selectedImageDetails);
      } else {
        console.error('Selected image does not have an "id" property.');
      }
    } else {
      console.error("Invalid imageSrc or slideIndex.");
    }
  };

  useEffect(() => {
    if (AiImagesproductData.length > 0) {
      handleImageClick(AiImagesproductData[0], 1);
    }
    // eslint-disable-next-line
  }, [AiImagesproductData]);
  //=======================================

  const fetchTileSizeData = async () => {
    try {
      const userToken = getToken();
      const apiUrl =
        (process.env.REACT_APP_API_URL as string) + "api/public_tiles_size";
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      const sizeOptions = response.data.data.map((item: any) => ({
        value: item.id,
        label: item.width + " x " + item.height,
      }));

      const selectedTilesSizeValue = getTilesFromQuerysizeString();

      setTilesizeOptions(sizeOptions);
      const selectedOptions = sizeOptions.filter((option: any) =>
        selectedTilesSizeValue.includes(option.value)
      );
      setSelectedTileSizes(selectedOptions);
    } catch (error) {
      console.error("Error fetching tile options:", error);
    }
  };

  function getTilesFromQuerysizeString() {
    const currentQueryString = window.location.search;
    const queryParams = new URLSearchParams(currentQueryString);
    const sizeParam = queryParams.getAll("size[]");

    return sizeParam.map((size) => parseInt(size));
  }

  const handleTileSizeChange = (selectedOptions: any) => {
    setSelectedTileSizes(selectedOptions);
  };
  //=======================================
  useEffect(() => {
    const userToken = getToken();
    if (userToken) {
      fetchTileSizeData();
      getTileColorData();
    }
    // eslint-disable-next-line
  }, []);

  //=======================================
  const getTileColorData = async () => {
    try {
      const userToken = getToken();
      const apiUrl =
        (process.env.REACT_APP_API_URL as string) + "api/public_product_colors";
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      const colorOptions = response.data.data.map((item: any) => ({
        value: item.id,
        label: item.title,
      }));

      setTilecolorOptions(colorOptions);

      const selectedTilesColorValue = getTilesFromQuerycolorString();

      const selectedOptions = colorOptions.filter((option: any) =>
        selectedTilesColorValue.includes(option.value)
      );
      setSelectedTileColor(selectedOptions);
    } catch (error) {
      console.error("Error fetching tile options:", error);
    }
  };

  function getTilesFromQuerycolorString() {
    const currentQueryString = window.location.search;
    const queryParams = new URLSearchParams(currentQueryString);
    const colorParam = queryParams.getAll("color[]");
    return colorParam.map((color) => parseInt(color));
  }

  const handleTileColorChange = (selectedOptions: any) => {
    setSelectedTileColor(selectedOptions);
  };
  //=======================================

  const applyFilters = () => {
    const currentQueryString = window.location.search;
    const queryParams = new URLSearchParams(currentQueryString);

    queryParams.delete("size[]");
    queryParams.delete("color[]");

    queryParams.set("search", searchQuery);

    selectedTileSizes.forEach((sizeOption) => {
      queryParams.append("size[]", sizeOption.value);
    });

    selectedTileColor.forEach((colorOption) => {
      queryParams.append("color[]", colorOption.value);
    });
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
    getProducts();
  };

  useEffect(() => {
    if (showBottomsSheetrModal) {
      fetchTileSizeData();
      getTileColorData();
    }
    // eslint-disable-next-line
  }, [showBottomsSheetrModal]);
  return (
    <div>
      <BottomSheet
        open={showBottomsSheetrModal}
        onDismiss={toggleBottomsheetModal}
        snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight - 120]}
      >
        <div style={{ padding: "20px", minHeight: "400px" }}>
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="mb-2"
          >
            <button
              type="button"
              className={`btn btn-sm search-wall-floor-btn  px-2`}
              style={{
                background: isForWall ? "#007bff" : "black",
              }}
              onClick={() => {
                setIsForWall((prevIsForWall) => !prevIsForWall);
                resetRotationAngles();
              }}
            >
              <input
                type="checkbox"
                className="form-check-input search-checkbox"
                checked={isForWall}
                onChange={() => { }}
              />
              Wall
            </button>
            <button
              type="button"
              className={`btn btn-sm search-wall-floor-btn ms-2`}
              style={{
                background: isForFloor ? "#007bff" : "black",
              }}
              onClick={() => {
                setIsForFloor((prevIsForFloor) => !prevIsForFloor);
                resetRotationAngles();
              }}
            >
              <input
                type="checkbox"
                className="form-check-input search-checkbox"
                checked={isForFloor}
                onChange={() => { }}
              />
              Floor
            </button>
          </div>
          <div className="input-group mb-3">
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  updateSelectedTileSizes(e.target.value);
                  updateSelectedTileColors(e.target.value);
                  if (e.target.value.trim() === "") {
                    setSelectedTileSizes([]);
                    setSelectedTileColor([]);
                  }
                }}
              />
            </InputGroup>
          </div>

          <div className="mb-2">
            <div>
              <Select
                isMulti
                options={tilesizeOptions}
                onChange={(selectedOptions) => {
                  handleTileSizeChange(selectedOptions);
                }}
                value={selectedTileSizes}
                placeholder={"Select Size"}
                className="h-100"
              />
            </div>
            <div className="mt-2">
              <Select
                isMulti
                options={tilecolorOptions}
                onChange={(selectedOptions) => {
                  handleTileColorChange(selectedOptions);
                }}
                value={selectedTileColor}
                placeholder={"Select Color"}
              />
            </div>
          </div>
          <div
            className="d-flex justify-content-between text-center mb-3"
            style={{ marginTop: "10px" }}
          >
            <button
              className={`btn btn-sm search-filter-btn`}
              onClick={() => {
                applyFilters();
                toggleBottomsheetModal();
              }}
            >
              Filter
            </button>

            <button
              className={`btn btn-sm search-close-btn`}
              onClick={toggleBottomsheetModal}
            >
              Close
            </button>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};

export default SearchBottomsheet;
