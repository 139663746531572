import React, { useEffect, useState } from "react";
import { getToken } from "../utils/authUtils";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import CatalogHeader from "./CatalogHeader";

interface ProductData {
    image_url: string;
    product_name_slug: string;
    height: number;
    width: number;
    is_for_floor: number;
    is_for_wall: number;
    created_at: string;
    updated_at: string;
}

const SeeCatalogDetails = () => {
    const { id } = useParams();
    const [specificProductData, setSpecificProductData] = useState<ProductData | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (token) {
            const getSpecificProduct = async (token: string) => {
                try {
                    const apiUrl = `${process.env.REACT_APP_API_URL}api/public_products/${id}`;
                    const response = await axios.get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setSpecificProductData(response.data.data);
                } catch (error) {
                    console.error("Error fetching data from the API:", error);
                }
            };
            getSpecificProduct(token);
        } else {
            console.error("No token available");
        }
    }, [id]);

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className="custom-container-see-catalog">
            <CatalogHeader showSearch={false} />
            <Container className="custom-container-details">
                <Row className="justify-content-center align-items-start">
                    <Col xs={12} md={6} className="d-flex justify-content-center mb-3 mb-md-0">
                        <div className="service-box-details">
                            <img
                                src={specificProductData?.image_url}
                                alt={specificProductData?.product_name_slug}
                            />
                        </div>
                    </Col>
                    <Col xs={11} md={6} className="d-flex justify-content-center ">
                        <div className="w-100 rounded bg-transparent border">
                            <div className="border-bottom bg-transparent p-3 d-flex justify-content-start align-items-center">
                                <h3 className="m-0 text-break details-header">
                                    {specificProductData?.product_name_slug
                                        ? specificProductData.product_name_slug
                                            .split("_")
                                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                            .join(" ")
                                        : "N/A"}
                                </h3>

                            </div>
                            <div className="p-3 bg-transparent">
                                {[
                                    {
                                        label: "Product type",
                                        value:
                                            specificProductData?.is_for_wall
                                                ? "Walls"
                                                : specificProductData?.is_for_floor
                                                    ? "Floor"
                                                    : "N/A",
                                    },
                                    { label: "Title", value: specificProductData?.product_name_slug || "-" },
                                    { label: "Width", value: specificProductData?.width || "-" },
                                    { label: "Height", value: specificProductData?.height || "-" },
                                    {
                                        label: "Created at",
                                        value: specificProductData?.created_at
                                            ? moment(specificProductData?.created_at).format("DD-MMM-YYYY")
                                            : "-",
                                    },
                                    {
                                        label: "Updated at",
                                        value: specificProductData?.updated_at
                                            ? moment(specificProductData?.updated_at).format("DD-MMM-YYYY")
                                            : "-",
                                    },
                                ].map((item, index) => (
                                    <Row
                                        key={index}
                                        className={`py-2 align-items-center`}
                                    >
                                        <Col xs={6} className="font-14-fw-500 details-title">
                                            {item.label}
                                        </Col>
                                        <Col xs={6} className="details-title text-break">
                                            {item.value}
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SeeCatalogDetails;
