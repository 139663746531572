import React from "react";
import { Routes, Route } from "react-router-dom";
import KiosksRoute from "./Kiosk/KiosksRoute";
import App from "./App";
import NotFound from "./App/Components/NotFound";
import ShowCaseRoute from "./ShowCase/ShowCaseRoute";

const MainApp = () => {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/ai-visualizer" element={<App />} />
      <Route path="/kiosksapp/*" element={<KiosksRoute />} />
      <Route path="/show-case/*" element={<ShowCaseRoute />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default MainApp;
