import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getToken } from "../utils/authUtils";

interface CatalogScreenProps {
    onBoxClick: () => void;
}

const CatalogScreen: React.FC<CatalogScreenProps> = ({ onBoxClick }) => {
    const navigate = useNavigate();
    const [boxCount, setBoxCount] = useState(3);
    const [searchParams] = useSearchParams();
    const [organization, setOrganization] = useState<any>(null);
    const [generalError, setGeneralError] = useState<string>("");
    const [loadingOrganization, setLoadingOrganization] = useState<boolean>(true);
    const organization_handle = searchParams.get("o");

    const handleBoxClick = (boxIndex: number) => {
        switch (boxIndex) {
            case 0:
                onBoxClick();
                break;
            case 1:
                navigate("/show-case/catalog-screen/see-catalog");
                break;
            case 2:
                navigate("/some-other-path");
                break;
            default:
                console.log("Invalid Box Clicked");
                break;
        }
    };

    useEffect(() => {
        const updateBoxCount = () => {
            setBoxCount(window.innerWidth <= 576 ? 1 : 3);
        };

        updateBoxCount();
        window.addEventListener("resize", updateBoxCount);
        return () => window.removeEventListener("resize", updateBoxCount);
    }, []);

    const getLogo = async () => {
        if (!organization_handle) return;

        const userToken = getToken();
        setLoadingOrganization(true);

        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}api/public/organizations/${organization_handle}`;
            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });

            setOrganization(response.data.data);
        } catch (error) {
            console.error("Error fetching organization logo:", error);
            setGeneralError("Failed to load organization details.");
        } finally {
            setLoadingOrganization(false);
        }
    };

    useEffect(() => {
        getLogo();
        // eslint-disable-next-line
    }, [organization_handle]);

    return (
        <div className="catalog-screen">
            <div
                style={{
                    margin: "20px auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    maxHeight: "110px",
                    height: "100%",
                    overflow: "hidden",
                }}
            >
                {loadingOrganization ? (
                    <div className="skeleton-catalog" style={{ height: "100px", width: "100px" }}></div>
                ) : organization?.logo ? (
                    <img
                        src={organization.logo}
                        alt="Organization Logo"
                        className="organization-logo"
                        height={"100px"}
                        width={"100px"}
                    />
                ) : (
                    <p className="error-text">{generalError || "Logo not available"}</p>
                )}
            </div>

            {/* Catalog Boxes */}
            <div className="catalog-screen-box">
                <div className="d-flex justify-content-evenly">
                    {[...Array(boxCount)].map((_, index) => (
                        <div
                            key={index}
                            className="screen-box-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleBoxClick(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CatalogScreen;
