import React, { useEffect, useState, useRef } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Thumbs } from "swiper/modules";
import axios from "axios";
import { getToken } from "../utils/authUtils";
import CatalogHeader from "./CatalogHeader";
import { useNavigate, useSearchParams } from "react-router-dom";

interface ProductData {
  id: number;
  image_url: string;
  product_name: string;
  tiles_size?: {
    width: number;
    height: number;
  };
  product_category?: {
    title: string;
  };
  product_type?: {
    title: string;
  };
}

const SeeCatalog = () => {
  const navigate = useNavigate();
  const [productData, setProductData] = useState<ProductData[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [slidesPerView, setSlidesPerView] = useState(4);
  const swiperRef = useRef<any>(null);

  useEffect(() => {
    setSlidesPerView(
      window.innerWidth < 480 ? 1 : window.innerWidth < 867 ? 2 : 4
    );

    const handleResize = () => {
      setSlidesPerView(
        window.innerWidth < 480 ? 1 : window.innerWidth < 867 ? 2 : 4
      );
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const token = getToken();

    if (token && (searchQuery.length === 0 || searchQuery.length >= 3)) {
      getProducts(token);
    }
  }, [searchQuery]);

  const getProducts = async (token: string) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/public_products/search?include=productType,tilesSize,productCategory,productColor`;

      const response = await axios.post(
        apiUrl,
        { search: { value: searchQuery } },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProductData(response.data.data);
    } catch (error) {
      console.error("Error fetching data from the API:", error);
    }
  };


  const handleSlideClick = (id: number) => {
    navigate(`/show-case/catalog-screen/see-catalog/${id}/see-catalog-details`);
  };

  return (
    <div className="custom-container-see-catalog">
      <CatalogHeader
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <div className="services" style={{ position: "relative" }}>
        <div className="swiper-holder">
          {productData.length > 0 && (
            <Swiper
              ref={swiperRef}
              slidesPerView={slidesPerView}
              spaceBetween={20}
              freeMode={true}
              loop={true}
              speed={1200}
              watchSlidesProgress={true}
              effect="slide"
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper m-0 custom-swiper"
              style={{ width: "100%", maxWidth: "auto", zIndex: "1000" }}
            >
              {productData.map((item) => (
                <SwiperSlide
                  key={item.id}
                  className="d-flex justify-content-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSlideClick(item.id)}
                >
                  <div className="service-box">
                    <div className="tile-details-box">
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="p-0 m-0 text-black-50 fw-bold">
                          33609 LT
                        </div>
                        <div className="p-0 m-0 fw-bold">
                          RANDOM: <span className="text-black-50">3</span>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center justify-content-between py-2"
                        style={{ borderTop: "1px dotted black" }}
                      >
                        <div className="p-0 m-0 fw-bold">
                          SURFACE:{" "}
                          <span className="text-black-50">
                            {item?.product_category?.title}
                          </span>
                        </div>
                        <div className="p-0 m-0 fw-bold">
                          SERIES:{" "}
                          <span className="text-black-50">
                            {item.product_type?.title}
                          </span>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center justify-content-between py-2"
                        style={{ borderTop: "1px dotted black" }}
                      >
                        <div className="p-0 m-0 fw-bold">
                          SIZE:{" "}
                          <span className="text-black-50">
                            {item?.tiles_size?.width}x{item?.tiles_size?.height}
                            mm
                          </span>
                        </div>
                        <div className="p-0 m-0 fw-bold">
                          THICKNESS: <span className="text-black-50">9mm</span>
                        </div>
                      </div>
                    </div>
                    <img src={item.image_url} alt={item.product_name} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </div>
  );
};

export default SeeCatalog;
