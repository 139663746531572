import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const shouldNavigateToKiosksApp =
    window.location.pathname.includes("kiosksapp") ||
    window.location.pathname.includes("kiosk");

  const handleNavigate = () => {
    if (shouldNavigateToKiosksApp) {
      navigate("/kiosksapp");
    } else {
      navigate(`/?${searchParams}`);
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center text-center"
      style={{ minHeight: "100vh" }}
    >
      <div>
        <img
          src="/images/notfound.svg"
          alt="Space AI Logo"
          className="img-fluid mb-4"
        />
        <h2 className="mb-3">Oops! That Page Can't Be Found</h2>
        <div>
          <button className="btn btn-sm uniform-btn" onClick={handleNavigate}>
            Go To Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
